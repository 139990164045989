.aboutme_portrait_container{
    width: 800px;
    border-radius: 15px;
    margin-left: 20px;
    height: 650px;
    border: 1px solid #eeeeee;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    background-image: url('../../../resources/images/backgrounds/aboutme_background.jpg');
    background-size: cover;
    background-position: -200px;
    position: relative;
}

.portrait_container_input{
    background-color: white;
    opacity: 0.8;
}

.img-container{
    height: 420px;
}

.img-container img{
    width: 98%;
    margin-left: 4px;
    margin-top: 4px;
    text-align: center;
    border-radius: 15px;
}

.carousel-container li {
    list-style-type: none;
}

.portrait_title{
    text-align: center;
    color: #fd8c3f;
    font-size: 26px;
    margin-top: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.portrait_name{
    text-align: center;
    font-family: Bahnschrift;
    font-size: 40px;
    padding-top: 10px;
    margin-top: 30px;
    border-top: 1px solid #e0e0e0;
}

.portrait_position{
    color: #b0b0b0;
    font-size: 17px;
    text-align: center;
    margin-top: -40px;
}

.portrait_icon_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.portrait_icon_container img{
    width: 30px;
    height: auto;
    margin-left: 50px;
}

@media (max-width: 1800px){
    .aboutme_portrait_container{
        width: 450px;
    }
}

@media (max-width: 1400px){

    .aboutme_portrait_container{
        margin: 0 auto;
        height: 400px;
        width: 30%;
        min-width: 350px;
        margin-bottom: 50px;
    }

    .img-container{
        height: 270px;
    }

    .portrait_name{
        font-size: 30px;
    }

    .portrait_title{
        font-size: 20px;
    }

    .portrait_position{
        margin-top: -35px;
    }

    .portrait_container_input{
        margin-top: -20px;
    }

}

@media (max-width: 400px){
    .aboutme_portrait_container{
        width: 100%;
        height: auto;
        margin-left: -15px !important;
    }
}

