.video-header {
    position: relative;
    width: 100%;
    transform: scale(1);

    transform-origin: top left;
}

.video-mobile {
    position: relative;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    transform: scale(1);
    transform-origin: top left;
}

.video-mobile video{
    width: 30vw;
    max-width: 350px;
    min-width: 250px;
    height: auto;
}

.video-header video {
    position: relative;
    width: 100%;
    border-radius: 25px;
    height: 56.25vw; /* Festgelegte Höhe basierend auf dem Seitenverhältnis 16:9 */
    max-height: calc(100vh - 100px);
    max-width: 2830px;
    margin-left: 2px;
    object-fit: cover;
}

.no_zoomed_video{
    object-fit: contain !important;
    height: 100% !important;
}

video{
    cursor: pointer;
}

@keyframes slide-up {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media (max-width: 850px){
    /* Styles für Smartphones */

    .video-header video{
        width: 100%;
    }

}


