.navigation_wrapper{
    border-radius: 10px;
    width: 100px;
    height: auto;
    background-color: white;
    animation: shadow-pulse 3s ease-in-out infinite;
    background-image: url('../../../resources/images/backgrounds/aboutme_background.jpg');
    background-size: cover;
    background-position: -300px;
}

.navigation_bar li{
    width: 100px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    list-style-type: none;
}

.navigation_bar_element{
    height: fit-content;
    width: 100px;
    border: 1px solid transparent;
    margin-left: -40px;
}

.selected_navigation{
    background-color: rgba(255, 236, 200, 0.5);
}

.navigation_bar_element:hover{
    border: 1px solid #fd8c3f;
    cursor: pointer;
}

.navigation_bar li p{
    text-align: center;
}

.navigation_img{
    width: 25px;
    margin-bottom: -15px;
    height: auto;
}


@media (max-width: 1800px){

    .navigation_wrapper ul{
        display: flex;
        align-items: center;
        margin-left: -40px;
        justify-content: center;
    }

    .navigation_container{
        margin: 0 auto;
    }

    .navigation_wrapper {
        width: 100%;
        background-size: auto;
        margin-top: -10vw;
    }

    .navigation_bar li{
        width: 100%;
    }

    .navigation_bar_element{
        margin-left: 0px;
    }
}

@media (max-width: 1400px){
    .navigation_wrapper{
        margin-top: -200px;
    }
}