.aboutme_container{
    width: 100%;
    height: 40vw;
    min-height: 850px;
    max-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    margin-bottom: -10px;
    background-image: url('../../resources/images/backgrounds/aboutme_background.jpg');
    background-size: cover;
}

.aboutme_wrapper{
    width: 90%;
    max-width: 1700px;
    margin: 100px;
    display: flex;

    align-items: center;
    vertical-align: center;
}

@media (max-width: 1800px){

    .aboutme_wrapper{
        display: block;
    }

    .aboutme_container{
        min-height: 1100px;
    }

}

@media (max-width: 1400px){

    .aboutme_container{
        height: 1600px;
        min-height: 1600px;
    }

}


