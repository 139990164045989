.main_body {
    position: relative;
}

.introduction_body {
    position: relative;
    display: flex;
    justify-content: center;
    height: auto;
    overflow: hidden;
}

.introduction_body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.introduction_body > * {
    position: relative;
    z-index: 1;

}

.events-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
}


.projects_body {
    display: flex;
    margin-top: 100px;
    justify-content: center;
    height: auto;
    min-height: 500px;
}

.portrait {
    display: flex;
    justify-content: center;
    padding: 140px 100px 100px;
    align-items: flex-start;
}

.portrait img {
    width: 60%;
    min-width: 250px;
    height: auto;
    opacity: 0.95;
    border-radius: 500px;
    border: 5px solid white;
}

.portrait img:hover{
    cursor: pointer;
}

.introduction {
    padding: 75px 100px 50px;
    text-align: center;
    width: 60%;
    max-width: 1000px;
    min-width: 300px;
    height: auto;
}


h1 {
    height: 75px;
    line-height: 75px;
    font-weight: bold;
    white-space: nowrap;
    font-size: 2vw;
}

h2 {
    width: 100%;
    font-size: 1.2rem;
    font-weight: lighter;
    padding: 20px;
    text-align: center;
    border: 1px solid #656565;
    color: #656565;
}

h3 {
    height: 75px;
    line-height: 75px;
    font-weight: normal;
    white-space: nowrap;
    font-size: 3.5rem;
}

.native_menuebar{
    position: absolute;
    display: flex;
    width: 100%;
    padding: 0;
    z-index: 1000;
    line-height: 1.0cm;
    border-radius: 10px;
    background: rgba(255,255,255,0.8);
    right: 0;
    visibility: collapse;
    margin: 20px 0 0;
}

.native_menuebar li {
    list-style: none;
    width: 100%;
    border: 1px solid #eeeeee;
    text-align: center;
    color: #6b6b6b;
    font-weight: lighter;
}

.native_menuebar li:hover {
    background-color: rgba(255, 236, 200, 0.5);
    cursor: pointer;
}

#animated-text {
    overflow: hidden;
    border-right: 0.15em solid white; /* Animationseffekt: blinkender Cursor */
    white-space: nowrap; /* Verhindert Zeilenumbrüche */
    margin: 0; /* Zur Sicherheit, um den Standardabstand zu entfernen */
    animation: typing 8s steps(40) infinite alternate;
}

#intro_job_title {
    color: #fd8c3f;
}

.loader{
    position: absolute;
    bottom: 0px;
}

.loader_text{
    font-size: 10px;
    margin-left: 20px;
    margin-top: -30px;
    color: #fd8c3f;
}

.invisible_writer_dot {
    animation: thepulse 1s ease-in-out infinite;
    vertical-align: text-bottom;
    display: inline-block;
    width: 1px;
    height: 1em;
    background-color: white;
    margin-left: 0.2em;
}

p {
    text-align: justify;
}

.introduction_text_container{
    width: 100%;
    color: white;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 20px;
}

.introduction_text {
    line-height: 0.9cm;
    font-size: 18px;
    hyphens: auto;
    color: black;
}

.introduction_final {
    font-size: 22px;
    color: black;
}

@keyframes thepulse {
    0%, 50% {
        opacity: 1;
    }
    51%, 100% {
        opacity: 0;
    }
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    }
    50% {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    }
}

@keyframes flameAnimation {
    0% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
    50% {
        transform: scale(1.3) translateY(-10px);
        opacity: 0.8;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.flame-emoji {
    animation: flameAnimation 1s ease-in-out infinite;
}

@media (min-width: 2500px){

    h1{
        font-size: 50px !important;
    }
}

@media (max-width: 850px){


    .introduction_body {
        display: inline;
    }

    .projects_body {
        margin-top: 60px;
    }

    .portrait {
        padding: 80px 0 20px;
    }

    .portrait img {
        width: 45%;
        min-width: 150px;
        border-width: 10px;
    }

    .introduction {
        padding: 20px 50px 20px 20px;
        width: 100%;
        max-width: none;
    }

    .introduction_text{
        margin: 20px;
    }

    .introduction_final{
        margin: 20px;
    }

    .introduction_text_container{
        position: relative;
        width: 88%;
        margin: 0 auto;
        left: -20px;
        height: auto;
    }

    h1 {
        height: 50px;
        line-height: 40px;
        font-size: 6vw;
    }

    h2 {
        width: 100%;
        padding: 10px;
        margin-left: -20px;
        font-size: 4vw;
    }

    h3 {
        font-size: 2rem;
    }

    #intro_job_title{
        font-size: 6vw;
    }

    .native_menuebar{
        visibility: visible;
    }

}

@font-face {
    font-family: 'Agency FB';
    src: url('../../resources/fonts/agency_fb.ttf') format('truetype');
}
