.footer {
    height: auto;
    width: auto;
    position: relative;
}

.footer_txt_wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(247, 153, 2, 0.26);
}

.upper_footer_wrapper {
    height: 250px;
    margin-top: -4px;
    background-color: rgba(247, 153, 2, 0.03);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_back {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.footer_back p{
    font-size: 24px;
    color: #fd8c3f;
    height: 75px;
    line-height: 75px;
    font-weight: bold;
    white-space: nowrap;
}

.footer_back p:hover {
    animation: pulse 1s ease-in-out infinite;
    cursor: pointer;
    color: lightgrey;
}

.downer_footer_wrapper {
    height: 200px;
    text-align: center;
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
    background-image: url('../../resources/images/backgrounds/footer_background.png');
    background-repeat: no-repeat; /* Verhindert die Wiederholung des Bildes */
    background-position: 0px -5vw;
    background-size: cover;
}

.footer_rights{
    text-align: center;
    margin-top: -15px;
    color: #9a9a9a;
}

.downer_footer_wrapper img:hover{
    animation: pulse 1s ease-in-out infinite;
}

#footer_logo{
    height: auto;
    width: 140px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 180px;
}

#footer_xing{
    height: auto;
    width: 30px;
    float: right;
    margin-right: 50px;
    margin-top: 30px;
}

#footer_linked_in{
    height: auto;
    width: 40px;
    float: right;
    margin-right: 50px;
    margin-top: 25px;
}

@media (min-width: 2300px){

    .downer_footer_wrapper{
        background-position: 0px -8vw;
    }


}

@media (max-width: 1500px){

    .downer_footer_wrapper{
        background-position: 0px -2vw;
    }


}

@media (max-width: 1000px){

    .downer_footer_wrapper{
        background-position: 0px 2vw;
    }


}

@media (max-width: 600px){

    #footer_xing, #footer_linked_in{
        position: absolute;
        visibility: collapse;
    }

    #footer_logo{
        margin-left: 20px;
    }

    .downer_footer_wrapper{
        background-position: 0px 18vw;
    }


}

