.projects{
    text-align: center;
    width: 100%;
    padding: 10px;
    margin-left: -5px;
}

.my_projects_headline_container {
    display: inline-block;
    width: 300px;
    height: auto;
    text-align: center;
    border: 5px solid #d3d3d3;
    border-radius: 500px;
    padding: 15px;
    background-color: white;
}

.project_timeline_long {
    position: relative;
    text-align: center;
    left: 50%;
    margin-top: 20px;
    width: 1px;
    height: 500px;
    background: linear-gradient(to top, rgba(253, 140, 63, 1) 0%, rgba(211, 211, 211, 0.5) 80%, rgba(211, 211, 211, 0) 100%);
}

.project_timeline_long::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -5px; /* Abstand vor dem ersten Strich anpassen */
    width: 10px; /* Breite des größeren Striches anpassen */
    height: 10px; /* Höhe des größeren Striches anpassen */
    border-radius: 100%;
    background-color: #fd8c3f; /* Farbe des größeren Striches anpassen */
}

.Collapsible{
    text-align: left;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    margin-bottom: 10px;
    color: black;
    box-shadow: 0 3px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.Collapsible__trigger{
    color: #fd8c3f;
    padding: 15px;
    font-size: 18px;
    display: block;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.Collapsible__trigger.is-open{
    border-bottom: 1px solid #d0d0d0;
}

.Collapsible__trigger:hover{
    background: rgba(253, 140, 63, 0.17);
}


.Collapsible__contentInner {
    text-align: center;
    padding: 10px;
}

.project_timeline_short {
    position: relative;
    text-align: center;
    left: 50%;
    margin-top: 20px;
    width: 1px;
    height: 100px;
    background: linear-gradient(to top, rgba(253, 140, 63, 1) 0%, rgba(211, 211, 211, 0.5) 50%, rgba(211, 211, 211, 0) 100%);
    content: '';
}

.horizontal_line_right {;
    display: inline-block;
    position: relative;
    width: 400px;
    left: 210px;
    height: 1px;
    background: linear-gradient(to left, rgba(253, 140, 63, 1) 0%, rgba(211, 211, 211, 0.5) 80%, rgba(211, 211, 211, 0) 100%);
}

#same_line_right{
    top: 20px;
    left: 450px;
}

#same_line_left{
    top: 20px;
    left: -450px;
}

.vs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-left: 10px;
    font-family: "Agency FB";
    font-size: 50px;
}


.dot_right {
    animation: pulse 1s ease-in-out infinite;
    vertical-align: text-bottom;
    display: inline-block;
    margin-left: 0.2em;
    position: absolute;
    top: -5px; /* Anpassen, um den Punkt zu positionieren */
    left: 395px; /* Anpassen, um den Punkt zu positionieren */
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fd8c3f;
}

.animate__pulse {
    animation: pulse 1s ease-in-out infinite;
    /* weitere Animationsstile */
}

.horizontal_line_left {;
    display: inline-block;
    position: relative;
    width: 400px;
    right: 210px;
    height: 1px;
    background: linear-gradient(to right, rgba(253, 140, 63, 1) 0%, rgba(211, 211, 211, 0.5) 80%, rgba(211, 211, 211, 0) 100%);
}

.dot_left {
    animation: pulse 1s ease-in-out infinite;
    vertical-align: text-bottom;
    display: inline-block;
    margin-left: 0.2em;
    position: absolute;
    top: -5px; /* Anpassen, um den Punkt zu positionieren */
    right: 395px; /* Anpassen, um den Punkt zu positionieren */
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fd8c3f;
}

#facial_mocap_text{
    left: 30px;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.timeline_header {
    position: absolute;
    top: -100px;
    font-family: "Agency FB";
}

.timeline_text{
    position: relative;
    top: 280px;
    left: -20px;
    color: lightgrey;
    font-size: 30px;
    rotate: -90deg;
}

.timeline_year_left{
    position: relative;
    left: -550px;
    font-size: 100px;
    font-family: "Agency FB";
    color: #fd8c3f;
}

.timeline_year_right{
    position: relative;
    left: 400px;
    font-size: 100px;
    font-family: "Agency FB";
    color: #fd8c3f;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white; /* Farbe des Pfeils */
}

.clickable {
    cursor: pointer;
}

.timeline_icon_right_one img {
    width: auto;
    height: 55px;
    position: absolute;
    top: -70px;
    left: 475px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.timeline_icon_right_two img {
    width: auto;
    height: 55px;
    position: absolute;
    top: -70px;
    left: 615px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.timeline_icon_right_three img {
    width: auto;
    height: 55px;
    position: absolute;
    top: -70px;
    left: 755px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.timeline_icon_left_one img {
    width: auto;
    height: 50px;
    position: absolute;
    top: -70px;
    left: -175px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.timeline_icon_left_two img {
    width: auto;
    height: 50px;
    position: absolute;
    top: -70px;
    left: -300px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.timeline_icon_left_three img {
    width: auto;
    height: 50px;
    position: absolute;
    top: -70px;
    left: -425px;
    padding: 15px;
    border-radius: 30px;
    animation: shadow-pulse 3s ease-in-out infinite;
}

.square-button {
    display: inline-block;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    text-align: center;
    font-size: 16px;
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
}

.square-button.expanded {
    width: 60%;
    max-width: 1200px;
    height: 500px;
    padding: 15px;
    background-color: transparent;
    animation: shadow-pulse 3s ease-in-out infinite;
}
.arrow-down {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    transition: transform 0.3s ease;
}

.content {
    width: 50%;
    min-width: 300px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.content.visible {
    opacity: 1;
    transform: scale(1);
}

.square-button.expanded .arrow-down {
    border-color: #fd8c3f;
}

.centered_content {
    text-align: center;
    padding: 20px;
}

.more_switch {
    position: relative;
    text-align: center;
    transform: scale(0.4);
    cursor: pointer;
    margin: -70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more_switch_background {
    position: absolute;
    z-index: 1;
}

.more_switch_button {
    position: relative;
    top: -15px;
    left: -140px;
    z-index: 3;
}

.more_switch_text{
    position: absolute;
    z-index: 2;
}

#more_text{
    color: #ffffff;
    top: 60px;
    left: 275px;
    font-size: 35px;
}

#less_text{
    color: white;
    top: 68px;
    left: 75px;
    font-size: 30px;
}

.switch_left_anim {
    animation: button-slide-left 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.switch_right_anim {
    animation: button-slide-right 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.max_width_text {
    max-width: 100%;
    word-wrap: break-word;
    line-height: 0.9cm;
    padding: 20px;
    font-size: 18px;
    margin: 0;
    color: black;
}

.source_text{
    font-size: 12px;
    color: grey;
    text-align: right;
    padding-right: 15px;
}

.highlighted_text{
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    color: #fd8c3f;
    font-size: 20px;
}

.project_img{
    width: 98%;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
}

.multi_pictures_container{
    display: flex;
    width: 100%;
    height: auto;
}

.multi_pictures_container_two{
    display: flex;
    width: 100%;
    height: auto;
}

.multi_pictures_container img{
    padding: 10px;
    max-width: 50%;
    height: auto;
    max-height: 450px;
}

.multi_pictures_container_two img{
    padding: 10px;
    height: auto;
    max-height: 450px;
}

.close_button_text{
    position: relative;
    padding-top: 10px;
    text-align: center;
    color: #fd8c3f;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: -5px;
}

a {
    color: #fd8c3f;
    text-decoration: none;
}

.mobile_newLine{
    display: none;
}

.model_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.model_left{
    position: relative;
    width: 100%;
    height: 100%;
    top: -100px;
    margin-bottom: -350px;
}

.button_model{
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -150px;
    margin-bottom: -300px;
}

.td_square-button {
    position: relative;
    display: inline-block;
    border: 1px solid #fd8c3f;
    padding: 0px 80px;
    border-radius: 10px;
    background-color: white;
    color: #fd8c3f;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
}

.td_square-button.expanded{
    border: 1px solid #fd8c3f;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    width: 60%;
    max-width: 1200px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
}

.td_square-button .arrow-down{
    border-color: #fd8c3f;
}

.button_or {
    position: relative;
    text-align: center;
    color: lightgrey;
}

.button_or::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -120px;
    width: 150px;
    height: 1px;
    background-color: lightgrey;
}

.button_or::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 120px;
    width: 150px;
    height: 1px;
    background-color: lightgrey;
}

.touchable{
    position: absolute;
    font-size: 20px;
    left: 90px;
    top: 140px;
    animation: pulse 1s ease-in-out infinite;
}

.more_projects_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.more_projects_container{
    padding-left: 10px;
    padding-right: 10px;
}

ul li {
    list-style: circle inside;
}

.more_projects_wrapper li{
    list-style: none;
}

@keyframes button-slide-left{
    0% {
    left: 0px;
    }
    100% {
    left: -140px;
    }
}

@keyframes button-slide-right{
    0% {
        left: -140px;
    }
    100% {
        left: 0px;
    }
}



@keyframes slide-up {
    0% {
        transform: translateY(50px); /* Startposition der Animation */
        opacity: 0; /* Startopazität */
    }
    100% {
        transform: translateY(0); /* Endposition der Animation */
        opacity: 1; /* Endopazität */
    }
}

@keyframes fill {
    0% {
        height: 0px;
    }
    100% {
        height: 500px;
    }
}

.animate {
    animation: slide-up 5s ease forwards;
}

@media (max-width: 850px){
    /* Styles für Smartphones */

    .projects {
        padding: 0;
    }

    .my_projects_headline_container {
        width: 40%;
        margin-left: 10px;
        max-width: 300px;
        min-width: 200px;
        padding: 0;
    }

    .project_timeline_long{
        height: 300px;
    }


    .timeline_text {
        font-size: 18px;
        top: 170px
    }

    .timeline_year_left {
        left: -35vw;
        top: 100px;
        font-size: 9vw;
    }

    .horizontal_line_right {
        width: 35vw;
        left: 25vw;
    }

    .dot_right {
        left: 34vw;
    }

    .timeline_year_right {
        left: 20vw;
        top: 100px;
        font-size: 9vw;
    }

    .horizontal_line_left {
        width: 35vw;
        left: -25vw;
    }

    #same_line_right{
        top: 20px;
        left: 45vw;
    }

    #same_line_left{
        top: 20px;
        left: -45vw;
    }


    .dot_left {
        left: -2vw;
    }

    .timeline_header {
        margin-top: 65px;
        font-size: 4.5vw;
    }

    .square-button.expanded{
        width: 95%;
    }

    .max_width_text{
        padding: 0px;
        font-size: 16px;
    }

    .highlighted_text{
        margin-left: 0px;
        margin-right: 0px;
    }

    .square-button{
        width: 95%;
        font-size: 12px;
    }

    .td_square-button{
        width: 95%;
        font-size: 12px;
        padding: 0px 0px;
    }

    .td_square-button.expanded{
        width: 100%;
    }

    .more_switch {
        transform: scale(0.3);
        margin: -80px;
    }

    .more_text{
        text-align: center;
    }

    .mobile_newLine{
        display: initial;
    }

    .multi_pictures_container{
        display: inline-block;
    }

    .multi_pictures_container_two{
        display: inline-block;
    }


    .timeline_icon_left_one, .timeline_icon_left_two, .timeline_icon_left_three,
    .timeline_icon_right_one, .timeline_icon_right_two, .timeline_icon_right_three{
        visibility: collapse;
    }

    .button_model {
        width: 100%;
    }

    .model_left{
        top: -120px;
    }

    .Collapsible__trigger:hover{
        background: transparent;
    }

}

@font-face {
    font-family: 'Agency FB';
    src: url('../../../resources/fonts/agency_fb.ttf') format('truetype');
    /* Weitere Optionen zur Schriftart wie font-weight, font-style, etc. */
}
