.header {
    padding: 20px 60px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
}

.logo img {
    width: 200px;
    height: auto;
}

.menu-bar {
    display: flex;
    gap: 100px;
    list-style-type: none !important;
}

.menu-bar a{
    color: initial !important;
}

.menu-bar li {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none !important;
    text-align: center;
}

.menu-bar li:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 130px;
    width: 130%;
    height: 200%;
    border: 1px solid #fd8c3f;
    transition: border-color 0.3s ease-in-out;
    border-radius: 6px;
    cursor: pointer;
}

@media (max-width: 850px){
    .header {
        padding: 20px 60px 20px 20px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
    }

    .menu-bar {
        display: none;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo img {
        padding: 20px;
        margin-left: 30px;
        width: 50vw;
        height: auto;
    }


}

